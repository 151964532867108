
import { Vue, Component } from 'vue-property-decorator';
import { Getter, Mutation } from 'vuex-class';
import { NavigationGuardNext, Route } from 'vue-router';
import PostSearchConditions from '@/model/posts/search/PostSearchConditions';
import PostsAndCommentApi from '@/module/api/PostsAndCommentApi';
import PostsAndCommentHeadline from '@/model/posts/PostsAndCommentHeadline';
import PostReaded from '@/model/posts/search/PostReaded';
import PostTypeList from '@/model/posts/search/PostTypeList';
import PostType from '@/model/posts/search/PostType';
import SendOrReceive from '@/model/posts/search/SendOrReceive';
import SearchTarget from '@/model/posts/search/SearchTarget';
import SearchKeyWord from '@/model/posts/search/SearchKeyWord';
import PostStatus, { DRAFT } from '@/model/posts/PostStatus';
import { COMMENT } from '@/model/posts/DataType';

Component.registerHooks([
  'beforeRouteEnter',
  'beforeRouteLeave'
]);
@Component({})
export default class MyPostList extends Vue {
  @Getter('isAdminGroup') isAdminGroup!: boolean;
  @Getter('pageCache') getPageCache!: (key:string)=> any;
  @Mutation('commitPageCache') commitPageCache!: any;

  searchConditions = new PostSearchConditions(new PostReaded(), new PostTypeList(), new SendOrReceive('SEND'), new SearchTarget(), new SearchKeyWord(), new PostStatus());
  posts:PostsAndCommentHeadline[] = [];
  /** 一度に表示する件数 */
  limit: number = 25;
  pageNumber: number = 1;
  dataLength: number = 0;
  selectTypeList: string[] = [];
  displayPath: string = this.$route.path;
  isFromVacation: boolean = false;

  beforeRouteEnter(to: Route, from: Route, next: NavigationGuardNext<MyPostList>): void {
    next((vm: MyPostList) => {
      if (to.query.vacation !== undefined && to.query.vacation === 'true') {
        vm.fromVacation(); // 休暇連絡画面から遷移時は検索条件をキャッシュに保存しない
      }
      vm.rememberPageView();
    });
  }

  fromVacation():void {
    this.isFromVacation = true;
  }

  rememberPageView(): void {
    const cache = this.getPageCache(this.displayPath);
    if (cache && !this.isFromVacation) {
      this.posts = cache.posts;
      this.searchConditions = cache.conditions;
      this.limit = cache.limit;
      this.pageNumber = cache.pageNumber;
      this.dataLength = cache.dataLength;
      this.setTypeParamForDisplay();
    } else {
      this.clear();
    }
  }

  beforeRouteLeave(to: Route, from: Route, next: NavigationGuardNext<MyPostList>): void {
    if (!this.isFromVacation) {
      this.commitPageCache(
        {
          pageName: this.displayPath,
          value: {
            posts: this.posts,
            conditions: this.searchConditions,
            limit: this.limit,
            pageNumber: this.pageNumber,
            dataLength: this.dataLength
          }
        }
      );
    }
    next();
  }

  mounted():void{
    if (this.posts.length === 0) {
      this.limit = 25;
      this.pageNumber = 1;
    }
    this.getData();
  }

  initialize():void {
    // 遷移元の指定によって初期の絞り込み条件を指定
    if (this.isFromVacation) {
      this.selectTypeList = ['vacation'];
    } else {
      this.selectTypeList = ['info', 'limit', 'comment', 'vacation', 'monthreport'];
    }
    this.searchConditions.target.value = ['title', 'register', 'message', 'comment'];
  }

  clear():void{
    this.initialize();
    // 検索実行
    this.getData();
  }
  search():void {
    this.pageNumber = 1;
    this.getData();
  }
  getPage(page: number):void {
    this.pageNumber = page;
    this.getData();
  }

  getData(): void {
    this.setTypeParam();
    PostsAndCommentApi.searchSendPosts(this.searchConditions, this.limit, this.pageNumber).then((res) => {
      this.posts = res.data.posts;
      this.dataLength = res.data.dataLength;
    });
  }

  readerLink(pPost: PostsAndCommentHeadline): string {
    const hasLimit = pPost.body.message.limitDate.value ? 'true' : 'false';
    return `/readedUsers/${pPost.postId.value}/${hasLimit}`;
  }

  showViewer(p: PostsAndCommentHeadline): boolean {
    if (p.dataType.value === COMMENT.value) return false;
    if (p.status.value === DRAFT.value) return false;
    return true;
  }
  // isDraft(p: PostsAndCommentHeadline): boolean {
  //   return true;
  //   // return p.status.value === DRAFT.value;
  // }

  get topRow(): number {
    if (this.dataLength === 0) return 0;
    return this.limit * (this.pageNumber - 1) + 1;
  }
  get endRow(): number {
    const end = this.limit * this.pageNumber;
    if (this.dataLength < end) return this.dataLength;
    return end;
  }
  get pages(): number {
    return Math.ceil(this.dataLength / this.limit);
  }

  setTypeParamForDisplay():void {
    this.selectTypeList = [];
    this.searchConditions.type.value.forEach((type) => {
      this.selectTypeList.push(type.value);
    });
  }
  setTypeParam():void{
    this.searchConditions.type.value = [];
    this.selectTypeList.forEach((type) => {
      this.searchConditions.type.value.push(new PostType(type));
    });
  }
  isCanSearch():boolean {
    if (this.selectTypeList.length > 0 && this.searchConditions.isRequireTarget()) {
      return true;
    }
    return false;
  }
}
